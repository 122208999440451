import React from "react";

import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageCard = ({ node }) => {
  const image = getImage(node);
  return (
    <>
      <div className="w-full h-full rounded-xl">
        <GatsbyImage image={image} alt={node.name} className="rounded-xl" />
      </div>
    </>
  );
};

const CreditCard = ({ data, pageContext, location }) => {
  const images = data.images.nodes;
  const itemCount = data.images.pageInfo.itemCount;

  return (
    <div className="">
      <p className="text-white text-6xl">Nah</p>
      {itemCount > 0 && (
        <>
          <h4 className="mb-5 mt-5 text-5xl">
            <span className="text-lg text-gray-500 font-semibold">
              {itemCount} photos
            </span>
          </h4>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
            {images.map((node) => (
              <ImageCard node={node} key={node.id} />
            ))}
          </div>

          <hr className="mt-4" />
        </>
      )}
    </div>
  );
};

export default CreditCard;

export const pageQuery = graphql`
  query {
    images: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        sourceInstanceName: { eq: "images" }
      }
      sort: { fields: name, order: ASC }
    ) {
      pageInfo {
        itemCount
      }
      nodes {
        name
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        }
      }
    }
  }
`;
